import { ATOTextBox } from "../../../../Components/ATOTextBox";
import { ATOValueHeader } from "../../../../Components/ATOValueHeader";
import { SinglePendingChanges } from "../../../../Hooks/SinglePendingChagesHook";
import { BillingInfo } from "../../../../generated";

import xeroLogo from "../../../../img/xero-logo.png";

export const PracticeDetailBillingInfo = ({
  data,
  pendingChanges,
}: {
  data: BillingInfo;
  pendingChanges: SinglePendingChanges<BillingInfo>;
}) => {
  return (
    <div className="grid h-full grid-rows-[min-content_minmax(0,1fr)]">
      <div className="flex w-full justify-between border-b border-black bg-primary p-2">
        <div className="w-0">
          <div
            className="aspect-square h-full scale-110 cursor-pointer overflow-clip rounded-full shadow-[0px_0px_0px_2px] shadow-white"
            onClick={() =>
              data.contactId && window.open(`https://go.xero.com/app/!g2C8Y/contacts/contact/${data.contactId}/details`)
            }
          >
            <img src={xeroLogo} />
          </div>
        </div>
        <h1 className="text-center text-xl text-white">Billing Info</h1>
        <div />
      </div>
      <div className="grid auto-rows-min grid-cols-[min-content_1fr_min-content_1fr] gap-2 p-2">
        <ATOValueHeader className="mt-1 flex-shrink-0 whitespace-nowrap" hasChange={pendingChanges.hasChange("name")}>
          Entity Name:
        </ATOValueHeader>
        <ATOTextBox
          className="w-full"
          hasChange={pendingChanges.hasChange("name")}
          disallowZeroLength
          value={data.name ?? ""}
          onChange={(val) => pendingChanges.setChange("name", val)}
        />
        <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("abn")}>
          ABN:
        </ATOValueHeader>
        <ATOTextBox
          className="w-full *:text-center"
          hasChange={pendingChanges.hasChange("abn")}
          disallowZeroLength
          value={data.abn?.toString() ?? ""}
          onChange={(val) => pendingChanges.setChange("abn", val)}
        />
        <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("address")}>
          Address:
        </ATOValueHeader>
        <ATOTextBox
          className="w-full"
          hasChange={pendingChanges.hasChange("address")}
          disallowZeroLength
          value={data.address ?? ""}
          onChange={(val) => pendingChanges.setChange("address", val)}
        />
        <ATOValueHeader className="mt-1 flex-shrink-0" hasChange={pendingChanges.hasChange("phoneNumber")}>
          Phone:
        </ATOValueHeader>
        <ATOTextBox
          className="w-full *:text-center"
          hasChange={pendingChanges.hasChange("phoneNumber")}
          disallowZeroLength
          value={data.phoneNumber ?? ""}
          onChange={(val) => (val === "" || !Number.isNaN(val)) && pendingChanges.setChange("phoneNumber", val)}
        />
      </div>
    </div>
  );
};
