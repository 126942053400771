import { useSetRecoilState } from "recoil";
import { TypedObject } from "../../../Helpers/TypedObject";
import { buildAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { Config } from "../../../Models/Config";
import { AMClient, BillingInfo, ModuleDetail } from "../../../generated";
import { PracticeDetailBillingInfo } from "../Detail/Billing/PracticeDetailBillingInfo";
import { PracticeDetailBillingModules } from "../Detail/Billing/PracticeDetailBillingModules";
import { SetupComponent } from "./SetupComponent";
import { AppModalAtom, BlockerAtom } from "../../../App";
import { useEffect, useState } from "react";
import { useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { BillingInfoDisplay } from "../Model/BillingInfoDisplay";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { ATOScopeChecker } from "../../../Components/ATOScopeChecker";
import { PracticeDetailBillingInvoiceHistory } from "../Detail/Billing/PracticeDetailBillingInvoiceHistory";
import { ATOLoading } from "../../../Components/ATOSpinner";

const PracticeCreationBillingValidationRules = {
  custId: (data) => !isNaN(data.custId as any),
} as {
  [index in keyof BillingInfo]: (data: BillingInfo) => boolean;
};

const saveBillingInfo = async (
  getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
  custId: number,
  data: BillingInfo
) => {
  const amBillingClient = getAmClient((cfg) => cfg.billingUrl);

  await amBillingClient.billingActions.updateBillingInfo({ billingInfo: { ...data } });

  await amBillingClient.billingActions.updateSubscriptionInfo({ subscriptionInfo: { ...data } });
};

const getBillingInfo = async (getAmClient: (getUrl: (cfg: Config) => string) => AMClient, custId: number) => {
  const amAdminClient = getAmClient((cfg) => cfg.adminUrl);
  const amBillingClient = getAmClient((cfg) => cfg.billingUrl);

  const dbPrac = (await amAdminClient.practiceDetails.getPracticeDetails({ custId: custId })).data;
  const dbBillingData = (await amBillingClient.billingActions.getBillingInfo({ custId: custId })).data;
  const dbSubscriptionData = (await amBillingClient.billingActions.getSubscriptionInfo({ custId: custId })).data;
  const moduleList = (await amAdminClient.practiceDetails.getModuleList({})).data!;

  const customerModules = moduleList.map((m) => {
    return { ...m, isEnabled: dbPrac?.modules?.includes(m.moduleId!) };
  });

  const data: BillingInfoDisplay = {
    ...dbBillingData,
    ...dbSubscriptionData,
    moduleList: customerModules,
  };

  return data;
};

export const PracticeBillingComponent: SetupComponent<BillingInfoDisplay> = {
  defaultData: getBillingInfo,
  validateData: (data) =>
    TypedObject.map(PracticeCreationBillingValidationRules, (key) =>
      PracticeCreationBillingValidationRules[key]!(data)
    ),
  saveData: saveBillingInfo,
  saveOrder: 2,
  Element: (props) => {
    return (
      <div
        style={{ gridTemplateColumns: `4fr ${props.data.custId ? "3fr" : ""}` }}
        className="grid h-full min-h-0 w-full grid-rows-[min-content_minmax(0,1fr)] gap-2 p-2"
      >
        <div className="h-full w-full overflow-clip rounded-lg border border-black">
          <PracticeDetailBillingInfo data={props.data} pendingChanges={props.pendingChanges} />
        </div>
        {props.data.custId && (
          <div className="row-span-2 overflow-clip rounded-md border border-black">
            <PracticeDetailBillingInvoiceHistory />
          </div>
        )}
        <div className="h-min w-full overflow-clip rounded-lg border border-b-0 border-black">
          <PracticeDetailBillingModules
            key={props.data.emailAddress}
            data={props.data!}
            pendingChanges={props.pendingChanges}
          />
        </div>
      </div>
    );
  },
};

export const PracticeBillingComponentElementWrapper = ({ custId }: { custId: number }) => {
  const user = useATOAuth();
  const config = useConfig();
  const setIsBlocked = useSetRecoilState(BlockerAtom);
  const setModal = useSetRecoilState(AppModalAtom);

  const getAmClient = (getUrl: (cfg: Config) => string, custId?: number) => buildAMClient(getUrl(config), user, custId);

  const [defaultData, setDefaultData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = () => {
    if (typeof PracticeBillingComponent.defaultData === "function") {
      const func = PracticeBillingComponent.defaultData as (
        getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
        custId: number
      ) => Promise<any>;

      func(getAmClient, custId)
        .then((data) => setDefaultData(data))
        .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
        .finally(() => setIsLoading(false));
    } else {
      setDefaultData(PracticeBillingComponent.defaultData);
      setIsLoading(false);
    }
  };

  useEffect(loadData, []);

  const PracticeBillingComponentElement = PracticeBillingComponent.Element;
  const pendingChanges = useSinglePendingChanges<BillingInfoDisplay>();

  const hasChanges = pendingChanges.listChanges().length > 0;

  const actualBillingDetail: BillingInfoDisplay = {
    ...(pendingChanges.applyChanges(defaultData ?? {}) ?? []),
  };

  useEffect(() => {
    setIsBlocked(hasChanges);
  }, [hasChanges]);

  if (isLoading) {
    return <ATOLoading />;
  }

  return (
    <ATOScopeChecker user={user} scope="billing" denyAction={"hide"}>
      <div className="flex h-full flex-col ">
        <PracticeBillingComponentElement
          custId={custId}
          data={actualBillingDetail}
          pendingChanges={pendingChanges}
          key={`PracticeBillingComponentElementWrapper-${custId}`}
        />
        <div className="flex h-16 w-full flex-shrink-0 items-center justify-end gap-2 bg-black px-2">
          <ATOButton
            buttonType={ButtonType.Warning}
            onClick={() => {
              pendingChanges.removeAllChanges();
            }}
          >
            Reset
          </ATOButton>
          <ATOButton
            buttonType={ButtonType.Confirm}
            disabled={!hasChanges}
            onClick={() => {
              setIsLoading(true);

              PracticeBillingComponent.saveData(getAmClient, custId, actualBillingDetail)
                .then(() => loadData())
                .then(() => pendingChanges.removeAllChanges())
                .catch((ex) => setModal(<ATODefaultAPIErrorModal error={ex} onButton={() => setModal(undefined)} />))
                .finally(() => setIsLoading(false));
            }}
          >
            Save
          </ATOButton>
        </div>
      </div>
    </ATOScopeChecker>
  );
};
