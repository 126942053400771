import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CSSProperties, useEffect, useRef, useState } from "react";
import { ATOClickOutside } from "./ATOClickOutside";
import { ATOSpinner } from "./ATOSpinner";
import { ATODropDown } from "./ATODropDown";
import { ATOTextBox } from "./ATOTextBox";

interface Props<T> {
  key?: string;
  className?: string;
  popupHeightRem?: number;
  popupStyle?: CSSProperties;

  getOptions: (search: string) => T[];
  selectedOptions: () => T[];
  getDataKey: (item?: T) => string;
  getItemDisplay: (item?: T) => React.ReactElement;
  onItemClick?: (item: T) => any;

  isItemDisabled?: (item: T) => boolean;
  disabledItemMessage?: string;
  enabled?: boolean;
  name?: string;
}

export const ATOMutliSelect = <T extends unknown>(props: Props<T>) => {
  const [searchKeyword, setSearchKeyword] = useState<string>();

  const spanRef = useRef<HTMLSpanElement>(null);

  // useEffect(() => {}, [searchKeyword]);

  const focusInput = () => {
    spanRef.current?.focus();
  };

  const allOptions = props.getOptions(searchKeyword ?? "");

  return (
    <ATOClickOutside onClick={() => setSearchKeyword(undefined)}>
      <div className={`${props.className ?? "h-full"} flex flex-col gap-2`}>
        <div>
          <div
            className={`${props.enabled === false ? "pointer-events-none opacity-50" : ""} flex cursor-text flex-wrap items-center gap-2 overflow-clip rounded-md p-2 shadow`}
            onClick={() => focusInput()}
          >
            {props.selectedOptions().map((o, i) => (
              <div
                className="group flex w-min cursor-pointer select-none items-center justify-center gap-2 text-nowrap rounded-lg border border-black bg-gray-300 px-2 py-1 transition-all hover:bg-primary hover:text-white"
                key={props.getDataKey(o)}
              >
                <p>{props.getItemDisplay(o)}</p>
                <FontAwesomeIcon
                  icon={faSvg.faTrash}
                  className="cursor-pointer pb-0.5 text-gray-500 transition-all hover:!text-red-600 group-hover:text-white"
                  onClick={() => props.onItemClick?.(o)}
                />
              </div>
            ))}
            <input
              type="text"
              className="w-full min-w-0 flex-shrink whitespace-nowrap"
              onChange={(val) => setSearchKeyword(val.target.value)}
              onFocus={() => setSearchKeyword("")}
              placeholder="Search..."
              value={searchKeyword ?? ""}
            />
            {/* <span
              ref={spanRef}
              className="whitespace-nowrap"
              role="textbox"
              onChange={(val) => setSearchKeyword(val.target.value)}}
              onFocus={() => setSearchKeyword("")}
              contentEditable
            ></span> */}
          </div>
        </div>
        {searchKeyword === undefined ? (
          <></>
        ) : (
          <div className="min-h-0 rounded-lg shadow">
            {allOptions.map((o, i) => (
              <>
                {i > 0 ? <div className="h-px w-full bg-gray-400" /> : <></>}
                <div
                  className="w-full cursor-pointer select-none text-center"
                  key={props.getDataKey(o)}
                  onClick={() => props.onItemClick?.(o)}
                >
                  {props.getItemDisplay(o)}
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    </ATOClickOutside>
    // <ATOClickOutside onClick={() => setIsPoppedUp(false)}>
    //   <div
    //     className={`cursor-pointer  ${props.enabled === false ? "pointer-events-none opacity-50" : ""} ${props.className ?? "h-12"}`}
    //   >
    //     <div
    //       className="z-10 flex h-full w-full bg-white"
    //       onClick={() => {
    //         focusInput();
    //         setIsPoppedUp(!isPoppedUp);
    //       }}
    //     >
    //       <div className="h-full w-full">
    //         <div className={`w-full rounded-md border ${isPoppedUp ? "border-blue-500" : "border-gray-400"}`}>
    //           {props.selectedOptions().map((item) => {
    //             return (
    //               <div
    //                 className="mx-[1px] mt-[2px] inline-block h-8 rounded-md
    //               border border-gray-400 bg-gray-200 px-[2px]
    //               py-0 align-middle text-sm leading-7 hover:border-blue-500 hover:bg-gray-50"
    //                 key={props.getDataKey(item)}
    //               >
    //                 {props.getDataKey(item)}
    //                 <FontAwesomeIcon
    //                   icon={faSvg.faMultiply}
    //                   onClick={() => props.onItemClick?.(item)}
    //                   className="inline cursor-pointer pl-[2px] text-gray-500 hover:text-red-600"
    //                 />
    //               </div>
    //             );
    //           })}
    //           <div className="h-full px-2 text-sm active:border-gray-200">
    //             <input
    //               type="text"
    //               className=""
    //               ref={inputRef}
    //               placeholder={`${props.selectedOptions().length === 0 ? "Select..." : ""}`}
    //               value={searchKeyword ?? ""}
    //               onChange={(e) => {
    //                 setSearchKeyword(e.target.value);
    //               }}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="relative">
    //         <div
    //           className="absolute flex h-full w-5 -translate-x-full transform items-center justify-center px-3"
    //           onClick={() => setIsPoppedUp(!isPoppedUp)}
    //         >
    //           <FontAwesomeIcon icon={faSvg.faAngleDown} className={`text-gray-400 ${isPoppedUp ? "rotate-180" : ""}`} />
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         height: `${isPoppedUp ? props.popupHeightRem ?? 10 : 0}rem`,
    //       }}
    //       className={`relative w-full rounded-lg ${isPoppedUp ? "" : "h-0"} flex justify-center`}
    //     >
    //       <div
    //         style={{ ...props.popupStyle, maxHeight: `${props.popupHeightRem ?? 10}rem` }}
    //         className={`absolute z-30 mt-2 flex w-full transform flex-col overflow-hidden overflow-y-auto rounded-lg shadow ${isPoppedUp ? "" : "h-0"}`}
    //       >
    //         <RenderItemCollection items={allOptions} />
    //       </div>
    //     </div>
    //   </div>
    // </ATOClickOutside>
  );

  function RenderItem(item: T) {
    let itemContent: string | JSX.Element = props.getItemDisplay(item);

    return (
      <div
        className={`text-center ${props.isItemDisabled?.(item) === true ? "cursor-not-allowed bg-gray-100 text-gray-500" : "cursor-pointer bg-white hover:bg-blue-50"}`}
        key={props.getDataKey(item)}
        onClick={() => {
          if (props.isItemDisabled?.(item) === true) {
            return;
          }

          setSearchKeyword("");
          props.onItemClick?.(item);
        }}
      >
        {itemContent}
        {props.isItemDisabled?.(item) === true && <span>&nbsp;{props.disabledItemMessage}</span>}
      </div>
    );
  }

  function RenderItemCollection({ items }: { items: T[] }) {
    // if (isLoading) {
    //   return (
    //     <div className="flex h-24 items-center justify-center bg-white">
    //       <ATOSpinner />
    //     </div>
    //   );
    // }

    if (items?.length > 0) {
      return items
        .map((x) => RenderItem(x))
        .reduce(
          (result, val) => (
            <>
              {result}
              <div className="w-full border border-b-0" />
              {val}
            </>
          ),
          <></>
        );
    } else {
      return <div className="flex items-center justify-center bg-white">No Results</div>;
    }
  }
};
