import { useSetRecoilState } from "recoil";
import { ATOModal } from "../../../Components/ATOModal";
import { AppModalAtom, BlockerAtom } from "../../../App";
import { useEffect, useState } from "react";
import { AMClient, BillingInfo, PracticeDetail } from "../../../generated";
import { SinglePendingChanges, useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { ATOButton, ButtonType } from "../../../Components/ATOButton";
import { TypedObject } from "../../../Helpers/TypedObject";
import { buildAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { SetupComponent } from "../Tabs/SetupComponent";
import { PracticeDetailComponent } from "../Tabs/PracticeDetailComponent";
import { PracticeBillingComponent } from "../Tabs/PracticeBillingComponent";
import { PracticeContactsComponent } from "../Tabs/PracticeContactsComponent";
import { Config } from "../../../Models/Config";

interface SetupStage<TData> {
  id: string;
  name: string;
  component: SetupComponent<TData>;
  pendingChanges: SinglePendingChanges<TData>;
}

export const PracticeCreationWizard = () => {
  const config = useConfig();
  const setModal = useSetRecoilState(AppModalAtom);
  const user = useATOAuth();

  const getAmClient = (getUrl: (cfg: Config) => string, custId?: number) => buildAMClient(getUrl(config), user, custId);

  const [innerModal, setInnerModal] = useState<React.ReactNode>(undefined);

  const stages: SetupStage<any>[] = [
    {
      id: "practice",
      name: "Practice Info",
      component: PracticeDetailComponent,
      pendingChanges: useSinglePendingChanges<PracticeDetail>(),
    } as SetupStage<PracticeDetail>,
    {
      id: "billing",
      name: "Billing Info",
      component: PracticeBillingComponent,
      pendingChanges: useSinglePendingChanges<BillingInfo>(),
    } as SetupStage<BillingInfo>,
    {
      id: "contacts",
      name: "Contacts",
      component: PracticeContactsComponent,
      pendingChanges: useSinglePendingChanges<BillingInfo[]>(),
    },
  ];

  const ProgressArrows = ({ currentStageIndex }: { currentStageIndex: number }) => {
    const doneClr = "#4ade80";
    const currentClr = "#38bdf8";
    const pendingClr = "#e5e7eb";

    return (
      <div className="flex h-10 w-full">
        {stages
          .map((s) => s.name)
          .map((title, index) => {
            let current = pendingClr;

            if (index < currentStageIndex) {
              current = doneClr;
            } else if (index === currentStageIndex) {
              current = currentClr;
            }

            let next = pendingClr;

            if (index + 1 < currentStageIndex) {
              next = doneClr;
            } else if (index + 1 === currentStageIndex) {
              next = currentClr;
            }

            return (
              <div
                key={index}
                style={{ backgroundColor: current }}
                className="flex h-full w-full items-center overflow-clip text-center"
              >
                <p className="w-full">{title}</p>
                {index !== stages.length - 1 && (
                  <div
                    style={{ backgroundColor: next }}
                    className="flex h-full w-[24px] items-center justify-end overflow-clip pr-[6px]"
                  >
                    <div
                      style={{ backgroundColor: current }}
                      className="aspect-square h-[80%] rotate-45 border-2 border-white"
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const [currentStageId, setCurrentStageId] = useState("practice");
  const currentStageIndex = stages.findIndex((s) => s.id === currentStageId);

  const currentStage: SetupStage<any> = stages.find((s) => s.id === currentStageId) ?? stages[0]!;

  const setIsBlocked = useSetRecoilState(BlockerAtom);
  const hasChanges = stages.map((s) => s.pendingChanges.listChanges().length > 0).reduce((prev, curr) => prev || curr);

  useEffect(() => {
    setIsBlocked(hasChanges);
  }, [hasChanges]);

  const handleNext = () => {
    const createPractice = currentStageIndex >= stages.length - 1;
    if (createPractice) {
      const orderedStages = stages.sort((a) => a.component.saveOrder);
      const practiceCreationStep = orderedStages[0];
      const remainingSteps = orderedStages.slice(1);

      const practiceData = practiceCreationStep.pendingChanges.applyChanges({});
      practiceCreationStep.component.saveData(getAmClient, 0, practiceData).then((custId) => {
        if (custId !== undefined && typeof custId === "number") {
          console.log("custId", custId);

          remainingSteps.map((s) => {
            try {
              var stepData = s.pendingChanges.applyChanges({});
              console.log("stepData", stepData);
              // s.component.saveData(getAmClient, custId, stepData);
            } catch (e) {}
          });
        }
      });
    } else {
      setCurrentStageId(stages[currentStageIndex + 1].id);
    }
  };

  const handleBack = () => {
    setCurrentStageId(stages[currentStageIndex - 1].id);
  };

  const CurrentStageElement = currentStage.component.Element ?? <></>;

  const length = stages.length;
  const [defaultData, setDefaultData] = useState<any[]>(Array.from({ length }, () => undefined));

  useEffect(() => {
    let finalData = [] as any[];

    stages
      .map((s) => s.component.defaultData)
      .forEach(async (d, i) => {
        if (typeof d === "function") {
          const func = d as (
            getAmClient: (getUrl: (cfg: Config) => string) => AMClient,
            custId: number
          ) => Promise<any>;

          func(getAmClient, 0).then((data) => (finalData[i] = data));
        } else {
          finalData[i] = d;
        }
      });

    setDefaultData(finalData);
  }, []);

  const currentStageDefaultData = defaultData[currentStageIndex];
  const currentStageData =
    currentStageDefaultData === undefined
      ? undefined
      : currentStage.pendingChanges.applyChanges(currentStageDefaultData);

  const validationData =
    currentStageData === undefined ? undefined : currentStage.component.validateData(currentStageData);

  const isCurrentStageMissingData =
    TypedObject.keys(validationData).find((key) => validationData?.[key as string | number] === true) !== undefined;

  const isLoading = defaultData.find((d) => d === undefined);

  return (
    <div className="h-full w-full">
      <ATOModal title={currentStage.name} onClose={(isClickOutside) => !isClickOutside && setModal(undefined)}>
        <div className="grid  h-[680px] w-[1200px] grid-cols-1 grid-rows-[min-content_1fr_min-content]">
          <div className="w-full px-2 py-1">
            <div className="w-full overflow-clip rounded-lg">
              <ProgressArrows currentStageIndex={currentStageIndex} />
            </div>
          </div>

          <CurrentStageElement custId={0} data={currentStageData} pendingChanges={currentStage.pendingChanges} />

          <div className="flex h-12 w-full justify-between bg-gray-200 p-2">
            <ATOButton buttonType={ButtonType.Error} onClick={() => setModal(undefined)}>
              Cancel
            </ATOButton>

            <div className="flex gap-2">
              <ATOButton buttonType={ButtonType.Warning} disabled={currentStageIndex === 0} onClick={handleBack}>
                Back
              </ATOButton>
              <ATOButton
                buttonType={ButtonType.Confirm}
                // disabled={isCurrentStageMissingData}
                onClick={handleNext}
              >
                {currentStageIndex >= stages.length - 1 ? "Create Practice" : "Next"}
              </ATOButton>
            </div>
          </div>
        </div>
      </ATOModal>
      {innerModal && <div className="absolute h-full w-full">{innerModal}</div>}
    </div>
  );
};
