import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";

import { ATOButton, ButtonType } from "../../../../Components/ATOButton";
import { useState } from "react";
import { ATOLoading } from "../../../../Components/ATOSpinner";
import { useAMClient, useATOAuth } from "../../../../Hooks/ATOAuthHook";
import { useConfig } from "../../../../Hooks/UseConfigHook";
import { CustomerContactDisplay, CustomerContactRole } from "../../../../generated";
import { EditContact } from "./EditContact";

export const PracticeDetailBillingContacts = ({
  custId,
  contacts,
  isStandAlone,
}: {
  custId: number;
  contacts: CustomerContactDisplay[];
  isStandAlone?: boolean;
}) => {
  const config = useConfig();
  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const [innerModal, setInnerModal] = useState<React.ReactNode>(undefined);

  const isSubscriptionOwnerPresent = contacts?.findIndex((c) => c.role === CustomerContactRole.SubscriptionOwner) > -1;

  const showEdit = (role: CustomerContactRole): boolean => {
    const restrictedRoles = [CustomerContactRole.SubscriptionOwner, CustomerContactRole.Billing];
    const flag =
      (restrictedRoles.includes(role) === true && user.scope?.includes("billing:write") === true) ||
      role != CustomerContactRole.SubscriptionOwner;
    return flag;
  };

  const columns = {
    role: { name: "Role", width: "1fr" },
    title: { name: "Title", width: "1fr" },
    firstName: { name: "First Name", width: "1fr" },
    surname: { name: "Surname", width: "1fr" },
    email: { name: "Email", width: "1fr" },
    phone: { name: "Phone Number", width: "1fr" },
    edit: { name: "", width: "52px" },
  };

  const spanString = `span ${Object.keys(columns).length * 2 - 1} / span ${Object.keys(columns).length * 2 - 1}`;

  return (
    <div className={`h-full w-full ${isStandAlone ? "px-[12%] py-4" : ""}`}>
      <div className={`flex h-full flex-col overflow-clip ${isStandAlone ? "rounded-xl border border-black " : ""}`}>
        <div
          className={`flex w-full items-center justify-between border-b border-black py-2 ${isStandAlone ? "bg-gray-200" : ""}`}
        >
          <div className="w-32" />
          {isStandAlone && <h1 className="text-center text-xl">Contacts</h1>}
          <div className="flex w-32 justify-end pr-2">
            <ATOButton
              buttonType={ButtonType.Confirm}
              onClick={() =>
                setInnerModal(
                  <EditContact
                    custId={custId}
                    isSubscriptionOwnerPresent={isSubscriptionOwnerPresent}
                    // reload={reloadContacts}
                    reload={() => {}}
                    setModal={setInnerModal}
                  />
                )
              }
            >
              <div className="flex items-center justify-between gap-2">
                {"Create "}
                <FontAwesomeIcon icon={faSvg.faPlusCircle} />
              </div>
            </ATOButton>
          </div>
        </div>
        <div
          style={{
            gridTemplateColumns: Object.values(columns)
              .map((v, i) => (i === 0 ? v.width : "1px " + v.width))
              .join(" "),
          }}
          className="grid h-full grid-rows-[min-content_minmax(0,1fr)]"
        >
          <div
            style={{
              gridTemplateColumns: "subgrid",
              gridColumn: spanString,
            }}
            className="grid items-center border-b border-black bg-primary text-white"
          >
            {Object.values(columns).map((c, i) => (
              <>
                {i > 0 ? <div className="h-4/5 bg-white" /> : <></>}
                <p className="w-full text-center">{c.name}</p>
              </>
            ))}
          </div>
          {user.isLoading ? (
            <div style={{ gridColumn: spanString }}>
              <ATOLoading />
            </div>
          ) : (
            <div
              style={{
                gridTemplateColumns: "subgrid",
                gridColumn: spanString,
              }}
              className="grid h-full auto-cols-fr place-content-start gap-y-2 overflow-y-scroll pt-2"
            >
              {contacts?.map((c, i) => (
                <div
                  key={`${c.title}-${c.customerContactId}`}
                  id={c.customerContactId?.toString() ?? (i - 100).toString()}
                  style={{
                    gridTemplateColumns: "subgrid",
                    gridColumn: spanString,
                  }}
                  className="mx-2 grid h-8 place-items-center overflow-clip rounded-lg border border-black bg-gray-200"
                >
                  <p>{c.role !== undefined ? CustomerContactRole[c.role] : "???"}</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>{c.title}</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>{c.firstName}</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>{c.surname}</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>{c.email}</p>
                  <div className="h-4/5 w-px bg-black" />
                  <p>{c.phone}</p>
                  <div className="h-4/5 w-px bg-black" />
                  {showEdit(c.role) && (
                    <FontAwesomeIcon
                      icon={faSvg.faEdit}
                      className="cursor-pointer"
                      onClick={() =>
                        setInnerModal(
                          <EditContact
                            custId={custId}
                            contact={c}
                            isSubscriptionOwnerPresent={isSubscriptionOwnerPresent}
                            // reload={reloadContacts}
                            reload={() => {}}
                            setModal={setInnerModal}
                          />
                        )
                      }
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {innerModal && <div className="absolute h-full w-full">{innerModal}</div>}
    </div>
  );
};
