import { Link, Navigate, Route, Routes, useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSvg from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { ATOLoading } from "../../../Components/ATOSpinner";
import { ATOScopeChecker } from "../../../Components/ATOScopeChecker";
import { PracticeDetail, ToolDetail } from "../../../generated";
import { AppModalAtom } from "../../../App";
import { useSetRecoilState } from "recoil";
import { ATODefaultAPIErrorModal } from "../../../Components/Modal/ATODefaultAPIErrorModal";
import { useConfig } from "../../../Hooks/UseConfigHook";
import { useSinglePendingChanges } from "../../../Hooks/SinglePendingChagesHook";
import { useAMClient, useATOAuth } from "../../../Hooks/ATOAuthHook";
import { ATOSuccessModal } from "../../../Components/ATOSuccessModal";
import { SettingsNavigation } from "../../../Dashboard/Settings/SettingsNavigation";
import Users from "./Users/Users";
import Workflows from "./Workflows/Workflows";
import { PracticeDetailDisplay } from "../Model/PracticeDetailDisplay";
import { PracticeDetailComponentWrapper } from "../Tabs/PracticeDetailComponent";
import { PracticeBillingComponentElementWrapper } from "../Tabs/PracticeBillingComponent";
import { PracticeContactsComponentWrapper } from "../Tabs/PracticeContactsComponent";

export const PracticeDetailNavigation = () => {
  const config = useConfig();
  const navigate = useNavigate();

  const user = useATOAuth();
  const amClient = useAMClient(config.adminUrl, user);

  const params = useParams();

  const id = parseInt(params.id ?? "");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [practiceDetail, setPracticeDetail] = useState<PracticeDetail>();

  const setModal = useSetRecoilState(AppModalAtom);

  useEffect(() => {
    if (!user.isLoading && !isLoading) {
      setIsLoading(true);

      amClient.practiceDetails
        .getPracticeDetails({ custId: id })
        .then((resp) => resp.data)
        .then((dbPrac) =>
          amClient.practiceDetails.getToolList({}).then((toolResp) => {
            return {
              ...dbPrac,
              toolList: toolResp.data,
            } as PracticeDetailDisplay;
          })
        )
        .then((data) => setPracticeDetail(data))
        .catch((ex) =>
          setModal(
            <ATODefaultAPIErrorModal
              error={{ message: ex.message }}
              onButton={() => {
                setModal(undefined);
                navigate("../");
              }}
            />
          )
        )
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  const isNewPractice = id === -1;

  const location = useLocation();

  const pendingChanges = useSinglePendingChanges<PracticeDetail>();

  const actualPracticeDetail = pendingChanges.applyChanges(practiceDetail ?? {});

  if (Number.isNaN(id)) {
    return <Navigate to="/Practices" />;
  }

  let currentRoute = location.pathname.replace(`/Practices/${id}`, "").split("/")[1];

  if (currentRoute === "") {
    currentRoute = "./";
  }

  const routes = [
    { name: "Billing", link: "./Billing", icon: faSvg.faMoneyCheckDollar, requiredScope: "billing" },
    { name: "Detail", link: "./", icon: faSvg.faClipboardList },
    { name: "Contacts", link: "./Contacts", icon: faSvg.faContactBook },
    { name: "Users", link: "./Users", icon: faSvg.faUser },
    ...(actualPracticeDetail ? [{ name: "Workflow", link: "./Workflows", icon: faSvg.faUsers }] : []),
    { name: "Practice Settings", link: "./Settings", icon: faSvg.faPersonThroughWindow },
  ];

  return (
    <div className="flex h-full flex-col">
      <header className="flex h-8 flex-shrink-0 justify-center bg-black text-white">
        {routes.map((r) => {
          const content = (
            <Link
              key={r.name}
              to={r.link}
              className={`flex h-full items-center gap-2 border-b-4 px-4 text-sm uppercase ${r.link.endsWith(currentRoute) ? "border-primary" : "border-black"} hover:border-blue-800`}
            >
              <FontAwesomeIcon icon={r.icon} />
              {r.name}
            </Link>
          );

          if (r.requiredScope) {
            return (
              <ATOScopeChecker key={r.name} user={user} scope={r.requiredScope} denyAction={"hide"}>
                {content}
              </ATOScopeChecker>
            );
          }

          return content;
        })}
      </header>
      <header className="flex justify-between bg-gray-200 px-4 py-2">
        <div className="flex w-32 flex-col gap-0">
          <p className="w-full text-nowrap text-2xl text-primary">
            {isNewPractice ? "New Practice" : actualPracticeDetail.custName ?? "..."}
          </p>
          <span>{isNewPractice ? "" : id}</span>
        </div>
        <div className="flex w-32 flex-col items-center justify-between">
          {(!actualPracticeDetail.active || !actualPracticeDetail.billingEnabled) && (
            <div className="flex w-min animate-pulse items-center justify-end gap-1 text-nowrap">
              <FontAwesomeIcon icon={faSvg.faWarning} className="mb-px text-yellow-400" />
              <p>Practice is not live</p>
            </div>
          )}
          <div className="flex w-min animate-pulse items-center justify-end gap-1 text-nowrap">
            <FontAwesomeIcon icon={faSvg.faStopCircle} className="text-red-400" />
            <p>Missing Subscription Owner/Billing User</p>
          </div>
        </div>
        <div className="flex w-32 flex-col items-end justify-between text-nowrap">
          <p>Billing Enabled: {practiceDetail?.billingEnabledDate?.split("T")[0] ?? "never"}</p>
          <div className="flex w-min animate-pulse items-center justify-end gap-1 text-nowrap">
            <FontAwesomeIcon icon={faSvg.faFileInvoiceDollar} className="text-red-400" />
            <p>Missing Billing Information</p>
          </div>
        </div>
      </header>
      <div className="h-full min-h-0 overflow-auto">
        {isLoading || user.isLoading ? (
          <ATOLoading />
        ) : (
          <Routes>
            <Route path="/Billing" element={<PracticeBillingComponentElementWrapper custId={id} />} />
            <Route
              path="/"
              element={
                <PracticeDetailComponentWrapper
                  custId={id}
                  practiceDetail={practiceDetail!}
                  setPracticeDetail={setPracticeDetail}
                />
              }
            />
            <Route path="/Contacts" element={<PracticeContactsComponentWrapper custId={id} setModal={setModal} />} />
            <Route path="/Workflows/*" element={<Workflows custId={id} />} />
            <Route path="/Users/*" element={<Users custId={id} />} />
            <Route path="/Settings/*" element={<SettingsNavigation custId={id} />} />
          </Routes>
        )}
      </div>
    </div>
  );
};
